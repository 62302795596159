exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-dicas-tsx": () => import("./../../../src/pages/dicas.tsx" /* webpackChunkName: "component---src-pages-dicas-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projetos-tsx": () => import("./../../../src/pages/projetos.tsx" /* webpackChunkName: "component---src-pages-projetos-tsx" */),
  "component---src-pages-sobre-tsx": () => import("./../../../src/pages/sobre.tsx" /* webpackChunkName: "component---src-pages-sobre-tsx" */),
  "component---src-pages-viagens-tsx": () => import("./../../../src/pages/viagens.tsx" /* webpackChunkName: "component---src-pages-viagens-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

